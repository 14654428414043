export default function HeroSection() {
  const handleEmailClick= ()=>{
    const email = "ashimshrestha2384@gmail.com"; 
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    
    window.open(gmailUrl, "_blank");
  }
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">  Hi, I'm Ashim Shrestha! 👋</p>
          
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Backend</span>{" "}
            <br />
            Developer
          </h1>
          <p className="hero--section-description">
        
           with a passion for building scalable web applications and solving complex problems.
            {/* <br />💼 Let's connect! Feel free to reach out to me on LinkedIn or explore my work on GitHub. */}
          </p>
        </div>
        <button className="btn btn-primary" onClick={handleEmailClick}>Get In Touch</button>
      </div>
      <div className="hero--section--img">
        <img src="./img/abc.png" alt="Hero Section" />
      </div>
    </section>
  );
}
