export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/coat.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          {/* <p className="section--title">About</p> */}
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
          I am a final-year Electronics, Communication, and Information Engineering student at Pashchimanchal Campus, IOE, with a passion for backend development and emerging technologies. My expertise lies in designing and building scalable server-side systems using modern tools and frameworks like NodeJS, ExpressJS, and NestJS, alongside a strong foundation in databases such as PostgreSQL, MongoDB, and Redis.
          </p>
          <p className="hero--section-description">
          I have completed several projects that demonstrate my ability to craft robust APIs, manage data workflows, and solve complex problems. Additionally, I have worked on projects in blockchain, utilizing technologies like Solidity, Hardhat, and IPFS to create decentralized applications. I am eager to apply my skills and knowledge to contribute to innovative solutions in backend development.
          </p>

        </div>
      </div>
    </section>
  );
}
